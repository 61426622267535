import React from "react";
import { Page, FormGroup, SubmitButton, Form } from "components/common";
import {Field, useFormik, FormikProvider, FieldArray} from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { Trans, useTranslation } from "react-i18next";
import {SharedUserSearch} from "../../features/organisations/SharedUserSearch";
import produce from "immer";
import {Button, Col, Row} from "reactstrap";

export const Edit = () => {
  const formik = useFormik({
    onSubmit,
  });
  const { resetForm, values } = formik;
  const navigate = useNavigate();
  const { id } = useParams();
  const client = useClient();
  const isNew = id === "new";
  const url = "organisations";
  const title = isNew ? "Create Organisation" : "Edit Organisation";
  const { t } = useTranslation();

  async function onSubmit(x) {
    x = produce(x, draft => {
      draft.project_shares?.forEach(project_share => (
          project_share.users = project_share.users.map(i => i.id)
      ))
    });
    const values = await (isNew
      ? client.post(url, x)
      : client.put(`${url}/${id}`, x)
    ).get("data");
    navigate(`../${values.id}`, { replace: true });
    resetForm({ values });
    NotificationManager.success(t("Saved successfully"));
  }

  useAsyncEffect(async () => {
    const input = isNew
      ? {
          name: "",
        }
      : client.get(`${url}/${id}`).get("data");

    const [values] = await Promise.all([input]);

    resetForm({ values });
  }, []);

  if (!values) return null;

  return (
    <Page title={title} translateTitle>
      <FormikProvider value={formik}>
        <Form>
          <FormGroup label="Organisation" name="name" required>
            <Field name="name" className="form-control" validate={(v) => !v} />
          </FormGroup>
          <FormGroup label="Project shares">
            <FieldArray name="project_shares">
              {({ push, remove }) => (
                  <div>
                    {values.project_shares?.map((_, index) => (
                        <div key={index} className="mb-2">
                          <Row form>
                            <Col>
                              <Field name={`project_shares.${index}.users`} render={({ field, form }) => {
                                const { name } = field;
                                const { setFieldValue } = form;

                                function onChange(value) {
                                  setFieldValue(name, value);
                                }
                                return <SharedUserSearch {...field} onChange={onChange} organisation={form.values.id} />
                              }}>
                              </Field>
                            </Col>
                            <Col xs="auto">
                              <Button size="sm" color="danger" outline onClick={() => {
                                remove(index);
                              }}>
                                Remove
                              </Button>
                            </Col>
                          </Row>
                        </div>
                    ))}
                    <Button size="sm" outline onClick={() => {
                      push({ users: [] })
                    }}>
                      Add Project Share
                    </Button>
                  </div>
              )}
            </FieldArray>
          </FormGroup>
          <div className="flex justify-end">
            <SubmitButton>
              <Trans>Save</Trans>
            </SubmitButton>
          </div>
        </Form>
      </FormikProvider>
    </Page>
  );
};

export default Edit;
