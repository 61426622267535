import React from "react";
import { Page, FormGroup, SubmitButton } from "components/common";
import {
  Field,
  useFormik,
  FormikProvider,
  FieldArray,
  useFormikContext,
} from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { Trans, useTranslation } from "react-i18next";
import { uniqueId } from "lodash";
import { Table, Button } from "reactstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FastInput } from "../../components/common";
import FormCheck from "../../components/common/FormCheck";
import {useQuery} from "react-query";

const Teams = ({ name }) => {
  const { getFieldMeta } = useFormikContext();
  const { value } = getFieldMeta(name);
  const { t } = useTranslation();
  const teamName = t("Team name");

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <Table size="sm">
          <thead>
            <tr>
              <th>
                <Trans>Teams</Trans>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {value.map((i, idx) => (
              <tr key={i.id || i.key}>
                <td className="w-full">
                  <FormGroup name={`${name}.${idx}.name`} className="mb-0">
                    <Field
                      as={FastInput}
                      placeholder={teamName}
                      validate={(v) => !v}
                      name={`${name}.${idx}.name`}
                      className="form-control"
                    />
                  </FormGroup>
                </td>
                <td>
                  <Button
                    color="link"
                    className="text-red-500"
                    onClick={() => {
                      remove(idx);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={999}>
                <Button
                  color="link"
                  onClick={() => {
                    push({
                      key: uniqueId(),
                      name: "",
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  <Trans>Add another Team</Trans>
                </Button>
              </td>
            </tr>
          </tfoot>
        </Table>
      )}
    </FieldArray>
  );
};

const selectFoodDatabase = v => {
  return {
    ...v,
    name: `${v.country}: ${v.name}`
  }
}

const AdditionalDatabases = ({ name }) => {
  const { getFieldMeta, getFieldHelpers } = useFormikContext();
  const { value } = getFieldMeta(name);
  const { setValue } = getFieldHelpers(name);
  const client = useClient();
  const { data: foodDatabases } = useQuery(['food_databases', {
    params: {
      use_in_healthcare: false
    }
  }], ({ queryKey }) => client.get(...queryKey).get('data'), {
    select: v => v.map(selectFoodDatabase)
  });

  return (
    <div>
      <h4 className="text-lg font-medium mb-4">
        <Trans>Additional databases</Trans>
      </h4>
      <ul>
        {foodDatabases?.map((i) => (
          <li key={i.id}>
            <input type="checkbox" checked={value.includes(i.id)} onChange={e => {
              const { checked } = e.target;
              const { id } = i;
              const newValue = checked ? [...value, id] : value.filter(i => i !== id);
              setValue(newValue, true);
            }} /> {i.name}
          </li>
        ))}
      </ul>
    </div>

  );
};

export const HealthcareOrganisationEdit = () => {
  const formik = useFormik({
    onSubmit,
  });
  const { resetForm, values } = formik;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const client = useClient();
  const isNew = id === "new";
  const url = "healthcare_organisations";
  const title = isNew
    ? t("Create Healthcare Organisation")
    : t("Create Healthcare Organisation");

  async function onSubmit(x) {
    let values = null;
    try {
      values = await (isNew
        ? client.post(url, x)
        : client.put(`${url}/${id}`, x)
      ).get("data");
    } catch (ex) {
      let handled = false;

      ex?.forNonFieldErrors((err) => {
        if (err.code === "team_needed") {
          NotificationManager.error(t("One or more teams cannot be removed"));
          handled = true;
        }
      });

      if (!handled) throw ex;
      return;
    }

    navigate(`../${values.id}`, { replace: true });
    resetForm({ values });
    NotificationManager.success(t("Saved successfully"));
  }

  useAsyncEffect(async () => {
    const [values] = await Promise.all([
      client.get(`${url}/${id}`).get("data"),
    ]);

    resetForm({
      values,
    });
  }, []);

  if (!values) return null;

  const { invite_link, invitation_link, exclude_from_self_registration } = values;

  return (
    <Page title={title}>
      <FormikProvider value={formik}>
        <FormGroup label={t("Organisation name")} required name="name">
          <Field name="name" validate={(v) => !v} className="form-control" />
        </FormGroup>
        <FormCheck
          label={t("DOE enabled?")}
          input={
            <Field
              type="checkbox"
              name={"doe_enabled"}
              id="doe_enabled"
              className="form-check-input"
            />
          }
        />
        <FormCheck
          label={t("Exclude from healthcare dashboard?")}
          input={
            <Field
              type="checkbox"
              name={"exclude_from_dashboard"}
              id="exclude_from_dashboard"
              className="form-check-input"
            />
          }
        />
        <FormCheck
            label={t("Include time on food and recipe entries?")}
            input={
              <Field
                  type="checkbox"
                  name={"include_entry_time"}
                  id="include_entry_time"
                  className="form-check-input"
              />
            }
        />
        <FormCheck
          label={t("Exclude from patient self-registration?")}
          input={
            <Field
              type="checkbox"
              name={"exclude_from_self_registration"}
              id="exclude_from_self_registration"
              className="form-check-input"
            />
          }
        />
        {invite_link && !exclude_from_self_registration ? (
          <FormGroup label={t('Patient self registration link')} className="mt-4">
            <a rel="noopener noreferrer" href={invitation_link}>
              {invitation_link}
            </a>
          </FormGroup>
        ) : null}
        <hr />
        <Teams name="teams" />
        <hr />
        <AdditionalDatabases name="additional_databases" />
        <div className="flex">
          <SubmitButton className="ml-auto">
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};
