import AsyncSelect from "react-select/async";
import { useClient } from "../../hooks";
import { useTranslation } from "react-i18next";

export function SharedUserSearch({ onChange, onBlur, name, value, organisation }) {
  const client = useClient();
  const { t } = useTranslation();

  async function loadOptions(search, callback) {
    if (search.length < 2) {
      callback([]);
      return;
    }
    const data = await client
      .get("users", {
        params: {
          search,
          groups: "Researchers,Teachers",
          ordering: "name",
          organisation,
        },
      })
      .get("data");
    callback(data);
  }

  return (
    <AsyncSelect
      loadOptions={loadOptions}
      placeholder={t("Search by name/email...")}
      isClearable={false}
      isMulti
      getOptionLabel={(v) => `${v.full_name}: ${v.email}`}
      getOptionValue={(v) => v.id}
      onChange={onChange}
      value={value}
    />
  );
}
